@tailwind components;
@tailwind utilities;

@tailwind base;
html {
  scroll-behavior: smooth;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


#ovo-regular {
  font-family: "Ovo", serif;
  font-weight: 800;
  font-style: normal;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#home-logo {
  margin-bottom: -90px;
}

nav {
  background-color: white;
}

#white-h {
  color: white;
  font-family: Rust;
  letter-spacing: 1px;
  font-size: 60px;
  line-height: 100%;

  
}

#rust {
  font-family: Rust;
  letter-spacing: 1px;

}

#white {
  color: white;
  margin-bottom: -2em;
  line-height: 140%;
  
  
}

#stripe {
  height: 14px;
  width: 100vw;
}

#aboutheader {
  text-align: left;
  font-family: Rust;
  letter-spacing: 1px;

}

#about-content {
  text-align: left;
}

.invalid-feedback,
.empty-feedback {
  display: none;
}

.was-validated :placeholder-shown:invalid ~ .empty-feedback {
  display: block;
}

.was-validated :not(:placeholder-shown):invalid ~ .invalid-feedback {
  display: block;
}

.is-invalid,
.was-validated :invalid {
  border-color: #dc3545;
}

#contactheader {
  margin-top: 90px;
  margin-bottom: -90px;
}

#mpheader {
  margin-top: 90px;
  margin-bottom: -80px;
}



#marginstuff {
  margin-top: -90px;
}

#mp-list {
  justify-content: center;
  align-items: left;
  display: flex;
}

#button {
  padding: 10px;
  margin:10px;
  font-family: Rust;
  letter-spacing: 1px;
  background-color: #F6B95B;
 
  
  
}

#button:hover {
  background-color: #372216; /* Green */
  color: white;
}

#button {
  transition-duration: 0.2s;
}

#rust-underline {
  font-family: Rust;
  letter-spacing: 1px;
  text-decoration: underline #F6B95B;
}
#bold {
  font-weight: bold;
}
@media (max-width: 640px) {
  #home-text {
    width: 92%;
    font-size: 20px;
    margin: auto;
    padding: 1px;
  }

  #white-h {
    font-size: 32px;
  }

  #mpintro {
    width: 80%;
  }

  #white {
    font-size: 16px;
    line-height: 160%;;

  }

  #height {
    height: auto;
  }

  

 }
 @media (max-width: 800px) {
  #height {
    height: auto;
  }
  

 }


#full {
  
}


 #mobile {
  background-color: white;
 }

 
 #footer {
  margin-top: -40px;
 }

 #mobilelogo {
  outline: none;
  text-align: left;
 }

 #iconicon {
  fill: red;
 }

 #mobilebanner {
  width: 100vw;
  margin-left: none;
  padding-left: none;
 }

 #footer-logo {
  height: 220px;
  margin-top: 20px;
 }

 #white-text {
  color: white;
 }

 #black {
  color: #010101;
 }

 #btn {
  font-family: Rust;
 }


#business img {
  height: 4.8em;
}

#redblock {
  height: .3em;
  width: 13%;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-items: center;
  margin: auto;
}

#podcast {
  width: 96%;
}

#rust-m {
  font-family: 'Rust';
  letter-spacing: .2em;

}

